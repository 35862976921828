.dpaForm {
  background: transparent;

  .logo {
    text-align: center;
  }

  h3 {
    margin: 20px;
    text-align: center;
  }

  .buttons {
    text-align: center;
    padding: 30px;
  }

  .group {
    .antCol:nth-child(odd) {
      background: #f1f1f1;
    }
  }

  .antCol {
    padding: 10px;
  }
}