@import "./mixins/mixins";

.GoogleSignInButton {
  max-width: 400px;
  min-width: 200px;

  border-radius: 4px;

  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  transition: background-color .218s, border-color .218s;
  background-color: #1a73e8;
  background-image: none;
  cursor: pointer;
  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  border: none;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;

  &:hover {
    box-shadow: none;
    border-color: #d2e3fc;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, .24);;
    }

    outline: none;
    color: #fff;
  }

  > p {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;
    width: 100%;

    > span {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      height: 100%;
      position: relative;
      width: 100%;
    }
  }

  .GoogleLogo {
    border-radius: 0 3px 3px 0;
    margin-left: 12px;
    margin-right: -10px;
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 36px;
    min-width: 36px;
    width: 36px;
    font-size: 18px;
    justify-content: center;
  }


}